#statistics {
    display: flex;
    flex-direction: column;
    margin: 60px 20px 20px 20px;
    max-width: 700px;
    width: 95%;
    height: 100vh;
}

#statistics .dropdowns {
    display: flex;

}

#chartContainer {
    width: 99%;
    height: 400px;
    border: 2px solid #1565C0;
}



@media screen and (max-width: 820px) {
    #statistics .dropdowns {
        display: flex;
        flex-direction: column;
    }
}


nav {
  position: fixed;
  height: 100vh;
  width: 270px;
  padding-top: 48px;
  box-sizing: border-box;
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  background-color: #01579B;
  z-index: 1000 }
  nav > ul {
    display: flex;
    flex-direction: column;
    list-style: none;
    margin: 0;
    width: 100%;
    height: 100%;
    max-height: calc(100vh - 250px);
    overflow-x: hidden;
    overflow-y: scroll; 
    justify-content: flex-start;
    box-sizing: border-box;
    padding: 0; }
    @media all and (max-width: 700px) {
      nav > ul {
        position: absolute; } }
    @media all and (max-device-width: 700px) {
      nav > ul {
        position: absolute; } }
    nav > ul > li {
      width: 100%;
      display: block;
      box-sizing: border-box;
      padding: 20px 30px;
      font-size: 18px;
      cursor: pointer;
      transition: opacity 250ms ease;
      color: #E3F2FD;}
      @media all and (max-width: 600px) {
        nav > ul > li{
          font-size: 16px;
          padding: 14px 30px;
           } }
           @media all and (max-height: 700px) {
             nav > ul > li{
               font-size: 16px;
               padding: 20px 30px;

               
               
                } }

      nav > ul > li:hover:not(.active) {
        background-color: #1976D2;
        color: #E3F2FD; }
    nav > ul > li.active {
      background-color: #1976D2;
      color: #E3F2FD; }
      
      /*This line hides scrollbar*/
      ::-webkit-scrollbar {
        width: 0px;  /* remove scrollbar space */
        background: transparent;  /* optional: just make scrollbar invisible */
    }

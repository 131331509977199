/* body select.select_box */
body select {
    margin: 0 10px 20px 10px;
    display: block;
    padding: 10px 70px 10px 13px !important;
    max-width: 100%;
    height: auto !important;
    border-radius: 8px;
    background: url(selectbox_arrow.png) right center no-repeat;
    background-color: #1565C0;
    color: white;
    font-size: 12px;
    line-height: 16px !important;
    appearance: none;
    /* this is must */
    -webkit-appearance: none;
    -moz-appearance: none;
    cursor: pointer;
    border: none;
}


/* body select.select_box option */
body select option {
    padding: 0 4px;
    cursor: pointer;
}

/* for IE and Edge */
select::-ms-expand {
    display: none;
}
select:disabled::-ms-expand {
    background: #f60;
}


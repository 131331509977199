table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    border: 1px solid #ddd;
    border-radius: 25px;
    overflow-x: scroll;
}


.tableBodyScroll tbody {
    display: block;
    overflow-y: scroll;
}

.tableBodyScroll thead, .tableBodyScroll tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
}

th, td {
    text-align: left;
    padding: 8px;
}

thead > tr {
    background-color: #64B5F6;
}

tbody > tr:nth-child(odd) {
    background-color: #fff;
}

tbody > tr:nth-child(even) {
    background-color: #E3F2FD;
}

.default-list-element:hover {
    background-color: #E2F1FD;
    cursor: pointer;
}
.inner-modal {
  width: 80vw;
  height: 80vh;
  background: white;
}

.modal-content {
  padding-left: 20px;
  padding-right: 20px;
  overflow-y: auto;
  height: calc(100% - 70px);
}

.exit:hover {
  background: rgba(255, 255, 255, 0.2);
}
@media screen and (max-width: 992px) {
  .inner-modal {
    width: 100%;
    height: 100%;
  }
}

.rectangle {
  fill: #b29696;
  fill-opacity: 0;
  stroke: #000000;
  stroke-width: 1.00157475;
  stroke-linejoin: round;
  stroke-miterlimit: 4;
  stroke-dasharray: none;
  stroke-opacity: 0;
  paint-order: markers stroke fill;
}

.group:hover > .chi {
  fill-opacity: 0.7;
}

.booked > .chi {
  fill: #e53935;
  fill-opacity: 1;
}

.available > .chi {
  fill: #43a047;
  fill-opacity: 1;
}

.newres > .chi {
  fill: #fbc02d;
  fill-opacity: 1;
}

.selected > .chi {
  fill-opacity: 1;
  stroke-width: 3;
  stroke: #fbc02d;
}

.eclipse {
  fill: #83beab;
  stroke-width: 2.25412297;
}
.Path-12-3 {
  display: inline;
  fill: #83beab;
  fill-rule: evenodd;
  stroke: none;
  stroke-width: 1;
}

#Rectangle-30  {
  fill: #d8d8d8;
}

#Rectangle-21 {
  fill: #83beab;
}

.path66 {
  fill: #d8d8d8;
  fill-opacity: 1;
}

.beab {
  fill: #83beab;
}

#path108 {
  fill: #93d877;
}

.lol {
  display: inline;
  fill: #83beab;
  fill-rule: evenodd;
  stroke: none;
  stroke-width: 1;
}

#path106 {
  fill: #93d877;
}

#path91 {
  fill: #93d877;
}

#path9 {
  stroke: #979797;
}

.rect-dark {
  fill: #83beab;
}

#Combined-Shape  {
  fill: #000000;
}

#chevron-left {
  fill: #93d877;
}

#layer3 {
  display: inline;
}

#Page-1 {
  display: inline;
  fill: #cfd8dc;
  fill-rule: evenodd;
  stroke: none;
  stroke-width: 1;
}

#Rectangle-30 {
  display: inline;
  fill: #cfd8dc;
  fill-rule: evenodd;
  stroke: none;
  stroke-width: 1;
}

.Path-12-3 {
  display: inline;
  fill: #83beab;
  fill-rule: evenodd;
  stroke: none;
  stroke-width: 1;
}

#Rectangle-30  {
  fill: #d8d8d8;
}

#Rectangle-21 {
  fill: #83beab;
}

.beab {
  fill: #83beab;
}

#path108 {
  fill: #93d877;
}

.lol {
  display: inline;
  fill: #83beab;
  fill-rule: evenodd;
  stroke: none;
  stroke-width: 1;
}

#path106 {
  fill: #93d877;
}

#path91 {
  fill: #93d877;
}

#path9 {
  stroke: #979797;
}

.rect-dark {
  fill: #83beab;
}

#Combined-Shape  {
  fill: #000000;
}

.out {
  stroke: #979797;
}

#chevron-left {
  fill: #93d877;
}

#layer3 {
  display: inline;
}

#Page-1 {
  display: inline;
  fill: #cfd8dc;
  fill-rule: evenodd;
  stroke: none;
  stroke-width: 1;
}

#Rectangle-30 {
  display: inline;
  fill: #cfd8dc;
  fill-rule: evenodd;
  stroke: #979797;
  stroke-width: 1;
}
#Rectangle-30  {
  fill: #d8d8d8;
}

#path108 {
  fill: #93d877;
}

#path106 {
  fill: #93d877;
}

#path91 {
  fill: #93d877;
}

.rect-dark {
  fill: #83beab;
}

#Combined-Shape  {
  fill: #d8d8d8;
}

#chevron-left {
  fill: #93d877;
}

#layer3 {
  display: inline;
}

#Page-1 {
  display: inline;
  fill: #cfd8dc;
  fill-rule: evenodd;
  stroke: none;
  stroke-width: 1;
}

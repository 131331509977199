.switchdiv {
    margin: 5px 10px 0px 0px;
    position: relative;
    display: inline-block;
}

.smaller {
    margin-bottom: 0px;
}

.switchcontainer {
    margin-bottom: 40px;
}

.employee {
    padding-top: 100px;
}

.lineunder {
    border-top: 1px solid grey;
    padding-bottom: 20px;
}

@media all and (max-width: 800px) {
    .title {
        margin-top: 5px;
    }
    .gap {
        padding-left: 45px;
    }
}

.change {
    background: #1565c0;
    color: #e3f2fd;
    cursor: pointer;
}

.more {
    background: #42a5f5;
    color: #e6f5ff;
    cursor: pointer;
}
.more:hover {
    background: #1565c0;
}

.delete {
    background: #c62828;
    color: #e3f2fd;
    cursor: pointer;
}

.save {
    background: #2e7d32;
    color: #e3f2fd;
    cursor: pointer;
}

.add-input {
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 10px;
    box-sizing: border-box;
    width: 100%;
    border: solid 1px #9e9e9e;
    -webkit-box-shadow: inset 1px 1px 2px 0 #707070;
    -moz-box-shadow: inset 1px 1px 2px 0 #707070;
    box-shadow: inset 1px 1px 2px 0 #707070;
    -webkit-transition: box-shadow 0.3s;
    -moz-transition: box-shadow 0.3s;
    -o-transition: box-shadow 0.3s;
    transition: box-shadow 0.3s;
}
.add-input:focus,
.add-input.focus {
    -webkit-box-shadow: inset 1px 1px 2px 0 #c9c9c9;
    -moz-box-shadow: inset 1px 1px 2px 0 #c9c9c9;
    box-shadow: inset 1px 1px 2px 0 #c9c9c9;
}

.add-inputG {
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 10px;
    box-sizing: border-box;
    width: 100%;
    border: solid 1px #9e9e9e;
    -webkit-box-shadow: inset 1px 1px 2px 0 #707070;
    -moz-box-shadow: inset 1px 1px 2px 0 #707070;
    box-shadow: inset 1px 1px 2px 0 #707070;
    -webkit-transition: box-shadow 0.3s;
    -moz-transition: box-shadow 0.3s;
    -o-transition: box-shadow 0.3s;
    transition: box-shadow 0.3s;
}
.add-inputG:focus,
.add-inputG.focus {
    -webkit-box-shadow: inset 1px 1px 2px 0 #c9c9c9;
    -moz-box-shadow: inset 1px 1px 2px 0 #c9c9c9;
    box-shadow: inset 1px 1px 2px 0 #c9c9c9;
}

.add-inputF {
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 10px;
    box-sizing: border-box;
    width: 80%;
    border: solid 1px #9e9e9e;
    -webkit-box-shadow: inset 1px 1px 2px 0 #707070;
    -moz-box-shadow: inset 1px 1px 2px 0 #707070;
    box-shadow: inset 1px 1px 2px 0 #707070;
    -webkit-transition: box-shadow 0.3s;
    -moz-transition: box-shadow 0.3s;
    -o-transition: box-shadow 0.3s;
    transition: box-shadow 0.3s;
}
.add-inputF:focus,
.add-inputF.focus {
    -webkit-box-shadow: inset 1px 1px 2px 0 #c9c9c9;
    -moz-box-shadow: inset 1px 1px 2px 0 #c9c9c9;
    box-shadow: inset 1px 1px 2px 0 #c9c9c9;
}

.add-inputSearch {
    margin-top: 10px;
    margin-bottom: 0px;
    padding: 10px;
    box-sizing: border-box;
    border: solid 1px #9e9e9e;
    -webkit-box-shadow: inset 1px 1px 2px 0 #707070;
    -moz-box-shadow: inset 1px 1px 2px 0 #707070;
    box-shadow: inset 1px 1px 2px 0 #707070;
    -webkit-transition: box-shadow 0.3s;
    -moz-transition: box-shadow 0.3s;
    -o-transition: box-shadow 0.3s;
    transition: box-shadow 0.3s;
}
.add-inputSearch:focus,
.add-inputSearch.focus {
    -webkit-box-shadow: inset 1px 1px 2px 0 #c9c9c9;
    -moz-box-shadow: inset 1px 1px 2px 0 #c9c9c9;
    box-shadow: inset 1px 1px 2px 0 #c9c9c9;
}

.searchButton {
    margin-top: 0px;
    margin-bottom: 0px;
}

.switchdiv {
    margin: 5px 10px 0px 0px;
    position: relative;
    display: inline-block;
}

.smaller {
    margin-bottom: 0px;
}

.switchcontainer {
    margin-bottom: 40px;
}

.disabled-input {
    width: 100%;
    max-width: 600px;
}

.karts {
  width: 38%;
  float: left;
}

@media only screen and (max-width: 1192px) {
  /* For mobile phones: */
  .karts {
    width: 100%;
  }
}

.cardres {
  background: #fff;
  border-radius: 2px;
  display: inline-block;
  height: 300px;
  margin: 1rem;
  position: relative;
  width: 300px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.switchdiv {
  margin: 5px 10px 0px 0px;
  position: relative;
  display: inline-block;
}
.add-inputSearch {
  margin-top: 4px;
  margin-bottom: 0px;
  padding: 10px;
  box-sizing: border-box;
  border: solid 1px #9e9e9e;
  -webkit-box-shadow: inset 1px 1px 2px 0 #707070;
  -moz-box-shadow: inset 1px 1px 2px 0 #707070;
  box-shadow: inset 1px 1px 2px 0 #707070;
  -webkit-transition: box-shadow 0.3s;
  -moz-transition: box-shadow 0.3s;
  -o-transition: box-shadow 0.3s;
  transition: box-shadow 0.3s;
}
.add-inputSearch:focus,
.add-inputSearch.focus {
  -webkit-box-shadow: inset 1px 1px 2px 0 #c9c9c9;
  -moz-box-shadow: inset 1px 1px 2px 0 #c9c9c9;
  box-shadow: inset 1px 1px 2px 0 #c9c9c9;
}

.editDate {
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px;
  box-sizing: border-box;
  width: 10vh;
  border: solid 1px #9E9E9E;
  -webkit-box-shadow: inset 1px 1px 2px 0 #707070;
  -moz-box-shadow: inset 1px 1px 2px 0 #707070;
  box-shadow: inset 1px 1px 2px 0 #707070;
  -webkit-transition: box-shadow 0.3s;
  -moz-transition: box-shadow 0.3s;
  -o-transition: box-shadow 0.3s;
  transition: box-shadow 0.3s;
}
.editDate:focus {
  -webkit-box-shadow: inset 1px 1px 2px 0 #c9c9c9;
  -moz-box-shadow: inset 1px 1px 2px 0 #c9c9c9;
  box-shadow: inset 1px 1px 2px 0 #c9c9c9;
}

.editNumber {
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px;
  box-sizing: border-box;
  width: 10vh;
  border: solid 1px #9E9E9E;
  -webkit-box-shadow: inset 1px 1px 2px 0 #707070;
  -moz-box-shadow: inset 1px 1px 2px 0 #707070;
  box-shadow: inset 1px 1px 2px 0 #707070;
  -webkit-transition: box-shadow 0.3s;
  -moz-transition: box-shadow 0.3s;
  -o-transition: box-shadow 0.3s;
  transition: box-shadow 0.3s;
}
.editNumber:focus {
  -webkit-box-shadow: inset 1px 1px 2px 0 #c9c9c9;
  -moz-box-shadow: inset 1px 1px 2px 0 #c9c9c9;
  box-shadow: inset 1px 1px 2px 0 #c9c9c9;
}

.showDate {
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px;
  box-sizing: border-box;
  border: solid 1px #9e9e9e;
  width: 10vh;
  background-color: #f8f8f8;
}

.showComment {
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px;
  box-sizing: border-box;
  border: solid 0.2px #9e9e9e;
  width: 80%;
  background-color: #f8f8f8;
}

.showNumbers {
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px;
  box-sizing: border-box;
  border: solid 1px #9e9e9e;
  width: 10vh;
  background-color: #f8f8f8;
}

.smaller {
  margin-bottom: 0px;
}

.switchcontainer {
  margin-bottom: 40px;
}

.employee {
  padding-top: 100px;
}

.lineunder {
  border-top: 1px solid grey;
  padding-bottom: 20px;
}

@media all and (max-width: 720px) {
  .title {
    margin-top: 5px;
  }
  .gap {
    padding-left: 45px;
  }
}

.change {
  background: #1565c0;
  color: #e3f2fd;
  cursor: pointer;
}

.delete {
  background: #c62828;
  color: #e3f2fd;
  cursor: pointer;
}

.save {
  background: #2e7d32;
  color: #e3f2fd;
  cursor: pointer;
}

.add-inputE {
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px;
  width: 80%;
  box-sizing: border-box;
  border: solid 1px #9e9e9e;
  -webkit-box-shadow: inset 1px 1px 2px 0 #707070;
  -moz-box-shadow: inset 1px 1px 2px 0 #707070;
  box-shadow: inset 1px 1px 2px 0 #707070;
  -webkit-transition: box-shadow 0.3s;
  -moz-transition: box-shadow 0.3s;
  -o-transition: box-shadow 0.3s;
  transition: box-shadow 0.3s;
}
.add-inputE:focus,
.add-inputE.focus {
  -webkit-box-shadow: inset 1px 1px 2px 0 #c9c9c9;
  -moz-box-shadow: inset 1px 1px 2px 0 #c9c9c9;
  box-shadow: inset 1px 1px 2px 0 #c9c9c9;
}

input:invalid {
  border: 1px solid red;
}

input:valid {
  border: 1px solid black;
}

.change {
  background: #1565c0;
  color: #e3f2fd;
  cursor: pointer;
}

.delete {
  background: #c62828;
  color: #e3f2fd;
  cursor: pointer;
}

.save {
  background: #2e7d32;
  color: #e3f2fd;
  cursor: pointer;
}

body {
    margin: 0;
    padding: 0;
    font-family: sans-serif;
}

.lineunderbold {
    border-top: 4px solid grey;
    padding-bottom: 20px;
}


nav {
  position: fixed;
  height: 100vh;
  width: 270px;
  padding-top: 48px;
  box-sizing: border-box;
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  background-color: #01579B;
  z-index: 1000 }
  nav > ul {
    display: flex;
    flex-direction: column;
    list-style: none;
    margin: 0;
    width: 100%;
    height: 100%;
    max-height: calc(100vh - 250px);
    overflow-x: hidden;
    overflow-y: scroll; 
    justify-content: flex-start;
    box-sizing: border-box;
    padding: 0; }
    @media all and (max-width: 700px) {
      nav > ul {
        position: absolute; } }
    @media all and (max-device-width: 700px) {
      nav > ul {
        position: absolute; } }
    nav > ul > li {
      width: 100%;
      display: block;
      box-sizing: border-box;
      padding: 20px 30px;
      font-size: 18px;
      cursor: pointer;
      -webkit-transition: opacity 250ms ease;
      transition: opacity 250ms ease;
      color: #E3F2FD;}
      @media all and (max-width: 600px) {
        nav > ul > li{
          font-size: 16px;
          padding: 14px 30px;
           } }
           @media all and (max-height: 700px) {
             nav > ul > li{
               font-size: 16px;
               padding: 20px 30px;

               
               
                } }

      nav > ul > li:hover:not(.active) {
        background-color: #1976D2;
        color: #E3F2FD; }
    nav > ul > li.active {
      background-color: #1976D2;
      color: #E3F2FD; }
      
      /*This line hides scrollbar*/
      ::-webkit-scrollbar {
        width: 0px;  /* remove scrollbar space */
        background: transparent;  /* optional: just make scrollbar invisible */
    }

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {display:none;}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #C62828;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2E7D32;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2E7D32;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.switchdiv {
  margin: 5px 10px 0px 0px;
  position: relative;
  display: inline-block;
}
.add-inputSearch {
    margin-top: 4px;
    margin-bottom: 0px;
    padding: 10px;
    box-sizing: border-box;
    border: solid 1px #9E9E9E;
    box-shadow: inset 1px 1px 2px 0 #707070;
    -webkit-transition: box-shadow 0.3s;
    transition: box-shadow 0.3s;
}
.add-inputSearch:focus, .add-inputSearch.focus {
    box-shadow: inset 1px 1px 2px 0 #c9c9c9;
}

.smaller {
  margin-bottom: 0px;
}

.switchcontainer {
  margin-bottom: 40px;
}


.employee {
  padding-top: 100px;
}

.lineunder {
  border-top: 1px solid grey;
  padding-bottom: 20px;
}

@media all and (max-width: 800px) {
      .title {
        margin-top: 5px; }
      .gap {
        padding-left: 45px;
      }

}



.add-inputE {
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px;
  width: 80%;
  box-sizing: border-box;
  border: solid 1px #9E9E9E;
  box-shadow: inset 1px 1px 2px 0 #707070;
  -webkit-transition: box-shadow 0.3s;
  transition: box-shadow 0.3s;
}
.add-inputE:focus, .add-inputE.focus {
  box-shadow: inset 1px 1px 2px 0 #c9c9c9;
}

input:invalid {
  border: 1px solid red;
}

input:valid {
  border: 1px solid black;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    border: 1px solid #ddd;
    border-radius: 25px;
    overflow-x: scroll;
}


.tableBodyScroll tbody {
    display: block;
    overflow-y: scroll;
}

.tableBodyScroll thead, .tableBodyScroll tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
}

th, td {
    text-align: left;
    padding: 8px;
}

thead > tr {
    background-color: #64B5F6;
}

tbody > tr:nth-child(odd) {
    background-color: #fff;
}

tbody > tr:nth-child(even) {
    background-color: #E3F2FD;
}

.default-list-element:hover {
    background-color: #E2F1FD;
    cursor: pointer;
}
/*.card {
    box-shadow: 0 8px 4px 0 rgba(0, 0, 0, 0.2);
    max-width: 300px;
    text-align: center;
    margin-bottom: 25px;
}
*/

.card {
  min-height: 100%;
  background: white;
  box-shadow: 0 8px 4px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  text-decoration: none;
  text-align: center;
  color: #444;
  position: relative;
  top: 0;
  -webkit-transition: all 0.1s ease-in;
  transition: all 0.1s ease-in;
  max-width: 300px;
}

html {
  background: #f5f7f8;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
}

.band {
  width: 100%;
  max-width: 1240px;
  margin: 0 auto;
  display: grid;

  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-gap: 20px;
}

@media only screen and (min-width: 970px) {
  .band {
    grid-template-columns: 1fr 1fr;
  }
}

@media only screen and (min-width: 1300px) {
  .band {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.bandP {
  width: 95%;
  max-width: 1240px;
  margin: 0 auto;
  display: grid;

  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-gap: 5px;
}

@media only screen and (min-width: 650px) {
  .bandP {
    grid-template-columns: 1fr 1fr;
  }
}

/* card */

.card {
  min-height: 100%;
  background: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  text-decoration: none;
  color: #444;
  position: relative;
  top: 0;
  -webkit-transition: all 0.1s ease-in;
  transition: all 0.1s ease-in;
}

.card:hover {
  top: -4px;
  box-shadow: 0 9px 8px rgba(0, 0, 0, 0.3);
}

.card article {
  padding: 20px;
  display: flex;

  flex: 1 1;
  justify-content: space-between;
  flex-direction: column;
}
.card .thumb {
  padding-bottom: 60%;
  background-size: cover;
  background-position: center center;
  background-image: url();
}

.card p {
  flex: 1 1; /* make p grow to fill available space*/
  line-height: 1.4;
  overflow-wrap: break-word;
  -ms-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}

.prodName {
  flex: 1 1;
  line-height: 1.6;
  overflow-wrap: break-word;
  hyphens: auto;
  max-width: 300px;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
}

/* typography */

.card span {
  font-size: 12px;
  font-weight: bold;
  color: #999;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  margin: 2em 0 0 0;
}

.price {
  color: black;
  font-size: 22px;
  font-weight: 600;
}

.true {
  color: green;
  font-size: 18px;
}
.w3-check {
  border: 2px;
  dotted: #00f;
  display: block;
  background: #ff0000;
}

.false {
  color: red;
  font-size: 18px;
}

.button1 {
  display: inline-block;
  padding: 15px 25px;
  border: none;
  border-radius: 8px;
  outline: none;
  display: inline-block;
  padding: 8px;
  color: white;
  background-color: #008cba;
  text-align: center;
  cursor: pointer;
  width: 100%;
  font-size: 14px;
  max-width: 100px;
  margin-bottom: 4px;
}

.a {
  text-decoration: none;
  font-size: 22px;
  color: black;
}

.button1:hover,
a:hover {
  opacity: 0.9;
}

.picture {
  max-width: 300px;
}

.switchdiv {
  margin: 5px 10px 0px 0px;
  position: relative;
  display: inline-block;
}

.smaller {
  margin-bottom: 0px;
}

.switchcontainer {
  margin-bottom: 40px;
}

.product {
  padding-top: 100px;
}

.lineunder {
  border-top: 1px solid grey;
  padding-bottom: 20px;
}

@media all and (max-width: 700px) {
  .title {
    margin-top: 5px;
  }
  .gap {
    padding-left: 45px;
  }
}

.label {
  margin-right: 7px;
  font-size: 18px;
  color: grey;
  justify-content: center;
}

.flex-container {
  margin-top: 5px;
  display: flex;
  justify-content: center;
  background-color: white;
}

.flex-div {
}

.change {
  background: #1565c0;
  color: #e3f2fd;
  cursor: pointer;
}

.delete {
  background: #c62828;
  color: #e3f2fd;
  cursor: pointer;
}

.save {
  background: #2e7d32;
  color: #e3f2fd;
  cursor: pointer;
}

.add-input {
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px;
  width: 80%;
  box-sizing: border-box;
  border: solid 1px #9e9e9e;
  box-shadow: inset 1px 1px 2px 0 #707070;
  -webkit-transition: box-shadow 0.3s;
  transition: box-shadow 0.3s;
}
.add-input:focus,
.add-input.focus {
  box-shadow: inset 1px 1px 2px 0 #c9c9c9;
}

.add-inputText {
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px;
  box-sizing: border-box;
  border: solid 1px #9e9e9e;
  box-shadow: inset 1px 1px 2px 0 #707070;
  -webkit-transition: box-shadow 0.3s;
  transition: box-shadow 0.3s;
  width: 100%;
  height: 70px;
}
.add-inputText:focus,
.add-inputText.focus {
  box-shadow: inset 1px 1px 2px 0 #c9c9c9;
}

.showInputText {
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px;
  box-sizing: border-box;
  border: solid 0.2px #9e9e9e;
  width: 80%;
  height: 70px;
  background-color: #f8f8f8;
}

.showInputN {
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px;
  box-sizing: border-box;
  border: solid 1px #9e9e9e;
  width: 50%;
  height: 30px;
  background-color: #f8f8f8;
}

.showInput {
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px;
  box-sizing: border-box;
  border: solid 0.2px #9e9e9e;
  width: 80%;
  height: 30px;
  background-color: #f8f8f8;
}

#settings {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

#settings .navbar {
  order: -1;
}

#settings .body {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  order: 0;
  align-items: center;
}
#settings .body .profile {
  display: flex;
  justify-content: space-around;
  padding: 10px;
  order: 0;
  flex: 1 0 auto;
  max-width: 700px;
  width: 100%;
  align-items: center;
}

.requiresReservation {
  font-size: 18;
}

.taxRate {
  font-size: 18;
}

@media screen and (max-width: 730px) {
  #settings .body {
    align-items: stretch;
  }
  #settings .body .profile {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
    order: 0; /* i forhold til notifications */
    flex: 1 0 auto;
  }
  #settings .body .profile #profileData {
    max-width: 270px;
    margin-bottom: 30px;
    padding-top: 0;
  }
  #settings .body .profile #profileData table {
    max-width: 300px;
    margin: 0;
  }
}

/* Profile picture */
#profilePicture {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 26px;
}

#profilePicture img {
  max-width: 270px;
  max-height: 270px;
  border-radius: 50%;
}
#profilePicture .file-upload {
  display: flex;
  justify-content: center;
  align-self: stretch;
  padding: 16px 0;
  background-color: #df8600;
  border: none;
  text-decoration: none;
  cursor: pointer;
  margin: 5px 0;
  color: black;
  outline: none;
  border-radius: 4px;
}
#profilePicture .file-upload:hover {
  border: 3px solid #646464;
}
#profilePicture .file-upload input {
}
#profilePicture .file-upload .fa-cloud-upload {
}

#profilePicture button {
  align-self: stretch;
  padding: 16px 0;
  background-color: black;
  border: none;
  text-decoration: none;
  cursor: pointer;
  margin: 5px 0;
  color: #a2a2a2;
  outline: none;
  border-radius: 4px;
}
#profilePicture button:hover {
  border: 3px solid #646464;
}

#profilePicture input[type="file"] {
  display: none;
}
#profilePicture .custom-file-upload {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
}

/* edit password button */
#editPasswordButton {
  display: flex;
  flex-direction: column;
  padding: 0 0;
}

#editPasswordButton button {
  align-self: stretch;
  padding: 16px 0;
  background-color: #df8600;
  border: none;
  text-decoration: none;
  cursor: pointer;
  margin: 5px 0;
  color: black;
  outline: none;
  border-radius: 4px;
}
#editPasswordButton button:hover {
  border: 3px solid #646464;
}

/* Profile data */
#profileData {
  display: flex;
  flex-direction: column;
  margin-top: 14px;
  padding-top: 140px;
}
#profileData table {
  margin: 0 0 0 0;
}
#profileData table tbody td {
  min-width: 100px;
  max-width: 150px;
  word-break: break-all;
}
/*
#profileData #userinfo {
    flex-basis: 270px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin: 0 10px;

}
#profileData #userinfo div{
    display: flex;
    justify-content: space-between;
}
*/

#profileData button {
  /*
    align-self: stretch;
     */
  background-color: #df8600;
  border: none;
  text-decoration: none;
  cursor: pointer;
  padding: 16px 16px;
  margin: 5px 2px 5px 2px;
  color: black;
  outline: none;
  border-radius: 4px;
}

#profileData button:hover {
  border: 3px solid #646464;
}

/* notifications */
#notifications {
  display: flex;
  flex-direction: column;
  align-self: center;
  margin-top: 20px;
  /*
    padding: 40px;
     */
  order: 1; /* i forhold til profile */
}
#notifications div {
  display: flex;
  margin: 6px;
}
#notifications div input[type="checkbox"] {
  width: 20px;
  height: 20px;
}
#notifications p {
  margin: 4px 0;
  text-align: center;
}
#notifications div label {
}
#notifications button {
  background-color: black;
  border: none;
  color: #a2a2a2;
  padding: 16px 32px;
  text-decoration: none;
  cursor: pointer;
  text-align: center;
  outline: none;
  border-radius: 4px;
  align-self: stretch;
}
#notifications button:hover {
  border: 3px solid #646464;
}

#settings .footer {
  order: 1;
}

input:invalid {
  border: 1px solid red;
}

input:valid {
  border: 1px solid black;
}

textarea:invalid {
  border: 1px solid red;
}

textarea:valid {
  border: 1px solid black;
}



.button {
  display: inline-block;
  padding: 15px 25px;
  font-size: 14px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  outline: none;
  color: #fff;
  background-color: #4CAF50;
  border: none;
  border-radius: 8px;
  margin-top: 10px;
  max-width: 700px;
}

.button:hover {background-color: #3e8e41}

.button:active {
  background-color: #3e8e41;
  box-shadow: 0 0px #666;
  -webkit-transform: translateY(4px);
          transform: translateY(4px);
}

.karts {
  width: 38%;
  float: left;
}

@media only screen and (max-width: 1192px) {
  /* For mobile phones: */
  .karts {
    width: 100%;
  }
}

.cardres {
  background: #fff;
  border-radius: 2px;
  display: inline-block;
  height: 300px;
  margin: 1rem;
  position: relative;
  width: 300px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  -webkit-transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.switchdiv {
  margin: 5px 10px 0px 0px;
  position: relative;
  display: inline-block;
}
.add-inputSearch {
  margin-top: 4px;
  margin-bottom: 0px;
  padding: 10px;
  box-sizing: border-box;
  border: solid 1px #9e9e9e;
  box-shadow: inset 1px 1px 2px 0 #707070;
  -webkit-transition: box-shadow 0.3s;
  transition: box-shadow 0.3s;
}
.add-inputSearch:focus,
.add-inputSearch.focus {
  box-shadow: inset 1px 1px 2px 0 #c9c9c9;
}

.smaller {
  margin-bottom: 0px;
}

.switchcontainer {
  margin-bottom: 40px;
}

.employee {
  padding-top: 100px;
}

.lineunder {
  border-top: 1px solid grey;
  padding-bottom: 20px;
}

@media all and (max-width: 720px) {
  .title {
    margin-top: 5px;
  }
  .gap {
    padding-left: 45px;
  }
}

.change {
  background: #1565c0;
  color: #e3f2fd;
  cursor: pointer;
}

.delete {
  background: #c62828;
  color: #e3f2fd;
  cursor: pointer;
}

.save {
  background: #2e7d32;
  color: #e3f2fd;
  cursor: pointer;
}

.add-inputE {
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px;
  width: 80%;
  box-sizing: border-box;
  border: solid 1px #9e9e9e;
  box-shadow: inset 1px 1px 2px 0 #707070;
  -webkit-transition: box-shadow 0.3s;
  transition: box-shadow 0.3s;
}
.add-inputE:focus,
.add-inputE.focus {
  box-shadow: inset 1px 1px 2px 0 #c9c9c9;
}

input:invalid {
  border: 1px solid red;
}

input:valid {
  border: 1px solid black;
}

.button {
  display: inline-block;
  padding: 15px 25px;
  font-size: 14px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  outline: none;
  color: #fff;
  background-color: #4caf50;
  border: none;
  border-radius: 8px;
  margin-top: 10px;
  max-width: 700px;
}

.button:hover {
  background-color: #3e8e41;
}

.button:active {
  background-color: #3e8e41;
  box-shadow: 0 0px #666;
  -webkit-transform: translateY(4px);
          transform: translateY(4px);
}

body {
  margin: 0;
  padding: 0;
  -webkit-transition: 200ms ease;
  transition: 200ms ease;
}

div {
  box-sizing: border-box;
}

input {
  outline: none;
  border: none;
}
h1 {
  margin-top: 60px;
  font-weight: 400;
  opacity: 0.8;
}

.checkBox {
  width: 20px;
  cursor: pointer;
}

.checkbox-label {
  z-index: 99999;
}

button {
  border: none;
  background: inherit;
}

li {
  padding: 0;
  margin: 0;
  list-style: none;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
  width: 100%;
  overflow: scroll;
}
ul > .row {
  height: 100vh;
}
@media all and (max-width: 700px) {
  div#root {
    display: flex;
  }
}
@media all and (max-device-width: 700px) {
  div#root {
    display: flex;
  }
}
div#root > main {
  overflow-x: hidden;
  height: inherit;
  position: relative;
  padding-left: 300px;
  padding-right: 28px;
  box-sizing: border-box;
}
div#root > main::before {
}
@media all and (max-width: 700px) {
  div#root > main {
    padding: 2%;
  }
  div#root > main::before {
    display: none;
  }
}
@media all and (max-device-width: 700px) {
  div#root > main {
    padding: 2%;
  }
  div#root > main::before {
    display: none;
  }
}

table#list {
  display: flex;
  margin: 30px 10px;
  width: 95%;
  height: auto;
  border-collapse: collapse;
  border-spacing: 0px 0px;
  box-shadow: 0px 10px 20px -5px rgba(0, 0, 0, 0.2);
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-bottom: 30px;
}
@media all and (max-width: 700px) {
  table#list {
    width: 100%;
    margin: 20px 0px 50px 0px;
  }
}
@media all and (max-device-width: 700px) {
  table#list {
    width: 100%;
    margin: 20px 0px;
  }
}
table#list > thead {
  width: 100%;
  display: flex;
  background-color: white;
  box-shadow: 0px 10px 20px -5px rgba(0, 0, 0, 0.2);
  padding: 15px 30px;
}
table#list > thead > tr {
  display: flex;
  justify-content: space-between;
  width: 100%;
  color: #7bb496;
}
table#list > tbody {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 0;
}
ul > .row > li {
  background-color: white;
  padding: 0;
  width: 100%;
  text-align: left;
  overflow: scroll;
}

/*#root {
  display: grid;
  height: 100vh;
  grid-template-columns: 200px auto; }
  @media all and (max-width: 800px) {
    #root {
      grid-template-columns: auto; } }
  @media all and (max-device-width: 800px) {
    #root {
      grid-template-columns: auto; } }
*/
/* Components */
form.search {
  padding: 20px 15px;
  position: relative;
}
form.search > input {
  width: 200px;
  box-shadow: 0px 10px 20px -5px rgba(0, 0, 0, 0.3);
  padding: 10px 15px 10px 35px;
  border-radius: 100px;
  font-size: 14px;
  -webkit-transition: 250ms ease;
  transition: 250ms ease;
  will-change: width;
  margin-right: 10px;
}
form.search > input:focus {
  width: 250px;
}
form.search > label {
  position: absolute;
  top: 30px;
  left: 25px;
  opacity: 0.6;
}

aside.modal {
  position: fixed;
  height: 100vh;
  left: 0;
  top: 0;
  width: 100vw;
  background-color: white;
  z-index: 99999;
  -webkit-transition: 250ms ease;
  transition: 250ms ease;
}
aside.modal.active {
  -webkit-transform: translateY(0%);
          transform: translateY(0%);
}
aside.modal.closed {
  -webkit-transform: translateY(-100%);
          transform: translateY(-100%);
}

.selected-day {
  background-color: tomato;
}


.selected-day-frontpage {
  background-color: #c8e6c9;
}
.calendar-day-frontpage {
  height: 70px;
}



/*# sourceMappingURL=main.css.map */

#statistics {
    display: flex;
    flex-direction: column;
    margin: 60px 20px 20px 20px;
    max-width: 700px;
    width: 95%;
    height: 100vh;
}

#statistics .dropdowns {
    display: flex;

}

#chartContainer {
    width: 99%;
    height: 400px;
    border: 2px solid #1565C0;
}



@media screen and (max-width: 820px) {
    #statistics .dropdowns {
        display: flex;
        flex-direction: column;
    }
}

/* body select.select_box */
body select {
    margin: 0 10px 20px 10px;
    display: block;
    padding: 10px 70px 10px 13px !important;
    max-width: 100%;
    height: auto !important;
    border-radius: 8px;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB8AAAAlCAYAAAC6TzLyAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyRpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTMyIDc5LjE1OTI4NCwgMjAxNi8wNC8xOS0xMzoxMzo0MCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6RkI1ODEwNTg2QTdEMTFFNkJDQkZBMTU4M0NBNEM1NEIiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6RkI1ODEwNTc2QTdEMTFFNkJDQkZBMTU4M0NBNEM1NEIiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoTWFjaW50b3NoKSI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjVBMjdEOUZENDU4QjExRTVCRTREOTRCQjhFMkEyQjg1IiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjVBMjdEOUZFNDU4QjExRTVCRTREOTRCQjhFMkEyQjg1Ii8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+A5VqQwAAAOBJREFUeNrsljEKwjAUhkNRBBHcLB7BRXAruLi6desBegCv5AEcXDoVxLG4ONcjuHaygvELvEkcFFIC5QU+fl5eyEdDWmrqum6ttSYEkTFmaAKNyAQcKle5ylWu8v7JB78urKrqSoxgkyTJ/Ut/RpzhQX/l+8ljWECJaPohdnUp/biLY0+hgSUUCMcidlnIfCPr/Mo5yots3MIaDognLqV286ms83/h2PhEZPCCLdwkXZ1Jv7vbjuBI5FLOJXOZ7/5VQ7QndvB0KfX/g79XG/LvVT+vKle5ylWu8n7I3wIMAHzY7QF4JpH/AAAAAElFTkSuQmCC) right center no-repeat;
    background-color: #1565C0;
    color: white;
    font-size: 12px;
    line-height: 16px !important;
    appearance: none;
    /* this is must */
    -webkit-appearance: none;
    -moz-appearance: none;
    cursor: pointer;
    border: none;
}


/* body select.select_box option */
body select option {
    padding: 0 4px;
    cursor: pointer;
}

/* for IE and Edge */
select::-ms-expand {
    display: none;
}
select:disabled::-ms-expand {
    background: #f60;
}


.switchdiv {
    margin: 5px 10px 0px 0px;
    position: relative;
    display: inline-block;
}

.smaller {
    margin-bottom: 0px;
}

.switchcontainer {
    margin-bottom: 40px;
}


.employee {
    padding-top: 100px;
}

.lineunder {
    border-top: 1px solid grey;
    padding-bottom: 20px;
}

@media all and (max-width: 800px) {
    .title {
        margin-top: 5px; }
    .gap {
        padding-left: 45px;
    }

}

.change {
    background: #1565C0;
    color: #E3F2FD;
    cursor: pointer;
}

.more {
    background: #42A5F5;
    color: #E6F5FF;
    cursor: pointer;
}
.more:hover {
    background: #1565C0;
}

.delete {
    background: #C62828;
    color: #E3F2FD;
    cursor: pointer;
}

.save {
    background: #2E7D32;
    color: #E3F2FD;
    cursor: pointer;
}

.add-input {
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 10px;
    box-sizing: border-box;
    border: solid 1px #9E9E9E;
    box-shadow: inset 1px 1px 2px 0 #707070;
    -webkit-transition: box-shadow 0.3s;
    transition: box-shadow 0.3s;
}
.add-input:focus, .add-input.focus {
    box-shadow: inset 1px 1px 2px 0 #c9c9c9;
}


.switchdiv {
    margin: 5px 10px 0px 0px;
    position: relative;
    display: inline-block;
}

.smaller {
    margin-bottom: 0px;
}

.switchcontainer {
    margin-bottom: 40px;
}
.inner-modal {
  width: 80vw;
  height: 80vh;
  background: white;
}

.modal-content {
  padding-left: 20px;
  padding-right: 20px;
  overflow-y: auto;
  height: calc(100% - 70px);
}

.exit:hover {
  background: rgba(255, 255, 255, 0.2);
}
@media screen and (max-width: 992px) {
  .inner-modal {
    width: 100%;
    height: 100%;
  }
}

.karts {
  width: 38%;
  float: left;
}

@media only screen and (max-width: 1192px) {
  /* For mobile phones: */
  .karts {
    width: 100%;
  }
}

.cardres {
  background: #fff;
  border-radius: 2px;
  display: inline-block;
  height: 300px;
  margin: 1rem;
  position: relative;
  width: 300px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  -webkit-transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.switchdiv {
  margin: 5px 10px 0px 0px;
  position: relative;
  display: inline-block;
}
.add-inputSearch {
  margin-top: 4px;
  margin-bottom: 0px;
  padding: 10px;
  box-sizing: border-box;
  border: solid 1px #9e9e9e;
  box-shadow: inset 1px 1px 2px 0 #707070;
  -webkit-transition: box-shadow 0.3s;
  transition: box-shadow 0.3s;
}
.add-inputSearch:focus,
.add-inputSearch.focus {
  box-shadow: inset 1px 1px 2px 0 #c9c9c9;
}

.smaller {
  margin-bottom: 0px;
}

.switchcontainer {
  margin-bottom: 40px;
}

.employee {
  padding-top: 100px;
}

.lineunder {
  border-top: 1px solid grey;
  padding-bottom: 20px;
}

@media all and (max-width: 720px) {
  .title {
    margin-top: 5px;
  }
  .gap {
    padding-left: 45px;
  }
}

.change {
  background: #1565c0;
  color: #e3f2fd;
  cursor: pointer;
}

.delete {
  background: #c62828;
  color: #e3f2fd;
  cursor: pointer;
}

.save {
  background: #2e7d32;
  color: #e3f2fd;
  cursor: pointer;
}

.add-inputE {
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px;
  width: 80%;
  box-sizing: border-box;
  border: solid 1px #9e9e9e;
  box-shadow: inset 1px 1px 2px 0 #707070;
  -webkit-transition: box-shadow 0.3s;
  transition: box-shadow 0.3s;
}
.add-inputE:focus,
.add-inputE.focus {
  box-shadow: inset 1px 1px 2px 0 #c9c9c9;
}

input:invalid {
  border: 1px solid red;
}

input:valid {
  border: 1px solid black;
}

.rectangle {
  fill: #b29696;
  fill-opacity: 0;
  stroke: #000000;
  stroke-width: 1.00157475;
  stroke-linejoin: round;
  stroke-miterlimit: 4;
  stroke-dasharray: none;
  stroke-opacity: 0;
  paint-order: markers stroke fill;
}

.group:hover > .chi {
  fill-opacity: 0.7;
}

.booked > .chi {
  fill: #e53935;
  fill-opacity: 1;
}

.available > .chi {
  fill: #43a047;
  fill-opacity: 1;
}

.newres > .chi {
  fill: #fbc02d;
  fill-opacity: 1;
}

.selected > .chi {
  fill-opacity: 1;
  stroke-width: 3;
  stroke: #fbc02d;
}

.eclipse {
  fill: #83beab;
  stroke-width: 2.25412297;
}
.Path-12-3 {
  display: inline;
  fill: #83beab;
  fill-rule: evenodd;
  stroke: none;
  stroke-width: 1;
}

#Rectangle-30  {
  fill: #d8d8d8;
}

#Rectangle-21 {
  fill: #83beab;
}

.path66 {
  fill: #d8d8d8;
  fill-opacity: 1;
}

.beab {
  fill: #83beab;
}

#path108 {
  fill: #93d877;
}

.lol {
  display: inline;
  fill: #83beab;
  fill-rule: evenodd;
  stroke: none;
  stroke-width: 1;
}

#path106 {
  fill: #93d877;
}

#path91 {
  fill: #93d877;
}

#path9 {
  stroke: #979797;
}

.rect-dark {
  fill: #83beab;
}

#Combined-Shape  {
  fill: #000000;
}

#chevron-left {
  fill: #93d877;
}

#layer3 {
  display: inline;
}

#Page-1 {
  display: inline;
  fill: #cfd8dc;
  fill-rule: evenodd;
  stroke: none;
  stroke-width: 1;
}

#Rectangle-30 {
  display: inline;
  fill: #cfd8dc;
  fill-rule: evenodd;
  stroke: none;
  stroke-width: 1;
}

.Path-12-3 {
  display: inline;
  fill: #83beab;
  fill-rule: evenodd;
  stroke: none;
  stroke-width: 1;
}

#Rectangle-30  {
  fill: #d8d8d8;
}

#Rectangle-21 {
  fill: #83beab;
}

.beab {
  fill: #83beab;
}

#path108 {
  fill: #93d877;
}

.lol {
  display: inline;
  fill: #83beab;
  fill-rule: evenodd;
  stroke: none;
  stroke-width: 1;
}

#path106 {
  fill: #93d877;
}

#path91 {
  fill: #93d877;
}

#path9 {
  stroke: #979797;
}

.rect-dark {
  fill: #83beab;
}

#Combined-Shape  {
  fill: #000000;
}

.out {
  stroke: #979797;
}

#chevron-left {
  fill: #93d877;
}

#layer3 {
  display: inline;
}

#Page-1 {
  display: inline;
  fill: #cfd8dc;
  fill-rule: evenodd;
  stroke: none;
  stroke-width: 1;
}

#Rectangle-30 {
  display: inline;
  fill: #cfd8dc;
  fill-rule: evenodd;
  stroke: #979797;
  stroke-width: 1;
}
#Rectangle-30  {
  fill: #d8d8d8;
}

#path108 {
  fill: #93d877;
}

#path106 {
  fill: #93d877;
}

#path91 {
  fill: #93d877;
}

.rect-dark {
  fill: #83beab;
}

#Combined-Shape  {
  fill: #d8d8d8;
}

#chevron-left {
  fill: #93d877;
}

#layer3 {
  display: inline;
}

#Page-1 {
  display: inline;
  fill: #cfd8dc;
  fill-rule: evenodd;
  stroke: none;
  stroke-width: 1;
}

.karts {
  width: 38%;
  float: left;
}

@media only screen and (max-width: 1192px) {
  /* For mobile phones: */
  .karts {
    width: 100%;
  }
}

.cardres {
  background: #fff;
  border-radius: 2px;
  display: inline-block;
  height: 300px;
  margin: 1rem;
  position: relative;
  width: 300px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  -webkit-transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.switchdiv {
  margin: 5px 10px 0px 0px;
  position: relative;
  display: inline-block;
}
.add-inputSearch {
  margin-top: 4px;
  margin-bottom: 0px;
  padding: 10px;
  box-sizing: border-box;
  border: solid 1px #9e9e9e;
  box-shadow: inset 1px 1px 2px 0 #707070;
  -webkit-transition: box-shadow 0.3s;
  transition: box-shadow 0.3s;
}
.add-inputSearch:focus,
.add-inputSearch.focus {
  box-shadow: inset 1px 1px 2px 0 #c9c9c9;
}

.editDate {
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px;
  box-sizing: border-box;
  width: 10vh;
  border: solid 1px #9E9E9E;
  box-shadow: inset 1px 1px 2px 0 #707070;
  -webkit-transition: box-shadow 0.3s;
  transition: box-shadow 0.3s;
}
.editDate:focus {
  box-shadow: inset 1px 1px 2px 0 #c9c9c9;
}

.editNumber {
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px;
  box-sizing: border-box;
  width: 10vh;
  border: solid 1px #9E9E9E;
  box-shadow: inset 1px 1px 2px 0 #707070;
  -webkit-transition: box-shadow 0.3s;
  transition: box-shadow 0.3s;
}
.editNumber:focus {
  box-shadow: inset 1px 1px 2px 0 #c9c9c9;
}

.showDate {
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px;
  box-sizing: border-box;
  border: solid 1px #9e9e9e;
  width: 10vh;
  background-color: #f8f8f8;
}

.showComment {
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px;
  box-sizing: border-box;
  border: solid 0.2px #9e9e9e;
  width: 80%;
  background-color: #f8f8f8;
}

.showNumbers {
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px;
  box-sizing: border-box;
  border: solid 1px #9e9e9e;
  width: 10vh;
  background-color: #f8f8f8;
}

.smaller {
  margin-bottom: 0px;
}

.switchcontainer {
  margin-bottom: 40px;
}

.employee {
  padding-top: 100px;
}

.lineunder {
  border-top: 1px solid grey;
  padding-bottom: 20px;
}

@media all and (max-width: 720px) {
  .title {
    margin-top: 5px;
  }
  .gap {
    padding-left: 45px;
  }
}

.change {
  background: #1565c0;
  color: #e3f2fd;
  cursor: pointer;
}

.delete {
  background: #c62828;
  color: #e3f2fd;
  cursor: pointer;
}

.save {
  background: #2e7d32;
  color: #e3f2fd;
  cursor: pointer;
}

.add-inputE {
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px;
  width: 80%;
  box-sizing: border-box;
  border: solid 1px #9e9e9e;
  box-shadow: inset 1px 1px 2px 0 #707070;
  -webkit-transition: box-shadow 0.3s;
  transition: box-shadow 0.3s;
}
.add-inputE:focus,
.add-inputE.focus {
  box-shadow: inset 1px 1px 2px 0 #c9c9c9;
}

input:invalid {
  border: 1px solid red;
}

input:valid {
  border: 1px solid black;
}

.switchdiv {
    margin: 5px 10px 0px 0px;
    position: relative;
    display: inline-block;
}

.smaller {
    margin-bottom: 0px;
}

.switchcontainer {
    margin-bottom: 40px;
}

.employee {
    padding-top: 100px;
}

.lineunder {
    border-top: 1px solid grey;
    padding-bottom: 20px;
}

@media all and (max-width: 800px) {
    .title {
        margin-top: 5px;
    }
    .gap {
        padding-left: 45px;
    }
}

.change {
    background: #1565c0;
    color: #e3f2fd;
    cursor: pointer;
}

.more {
    background: #42a5f5;
    color: #e6f5ff;
    cursor: pointer;
}
.more:hover {
    background: #1565c0;
}

.delete {
    background: #c62828;
    color: #e3f2fd;
    cursor: pointer;
}

.save {
    background: #2e7d32;
    color: #e3f2fd;
    cursor: pointer;
}

.add-input {
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 10px;
    box-sizing: border-box;
    width: 100%;
    border: solid 1px #9e9e9e;
    box-shadow: inset 1px 1px 2px 0 #707070;
    -webkit-transition: box-shadow 0.3s;
    transition: box-shadow 0.3s;
}
.add-input:focus,
.add-input.focus {
    box-shadow: inset 1px 1px 2px 0 #c9c9c9;
}

.add-inputG {
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 10px;
    box-sizing: border-box;
    width: 100%;
    border: solid 1px #9e9e9e;
    box-shadow: inset 1px 1px 2px 0 #707070;
    -webkit-transition: box-shadow 0.3s;
    transition: box-shadow 0.3s;
}
.add-inputG:focus,
.add-inputG.focus {
    box-shadow: inset 1px 1px 2px 0 #c9c9c9;
}

.add-inputF {
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 10px;
    box-sizing: border-box;
    width: 80%;
    border: solid 1px #9e9e9e;
    box-shadow: inset 1px 1px 2px 0 #707070;
    -webkit-transition: box-shadow 0.3s;
    transition: box-shadow 0.3s;
}
.add-inputF:focus,
.add-inputF.focus {
    box-shadow: inset 1px 1px 2px 0 #c9c9c9;
}

.add-inputSearch {
    margin-top: 10px;
    margin-bottom: 0px;
    padding: 10px;
    box-sizing: border-box;
    border: solid 1px #9e9e9e;
    box-shadow: inset 1px 1px 2px 0 #707070;
    -webkit-transition: box-shadow 0.3s;
    transition: box-shadow 0.3s;
}
.add-inputSearch:focus,
.add-inputSearch.focus {
    box-shadow: inset 1px 1px 2px 0 #c9c9c9;
}

.searchButton {
    margin-top: 0px;
    margin-bottom: 0px;
}

.switchdiv {
    margin: 5px 10px 0px 0px;
    position: relative;
    display: inline-block;
}

.smaller {
    margin-bottom: 0px;
}

.switchcontainer {
    margin-bottom: 40px;
}

.disabled-input {
    width: 100%;
    max-width: 600px;
}

@media screen and (max-height: 700px) {
    .del {
        align-items: unset !important;
        overflow: scroll;
    }
    .delCard {
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
    }
}

.change {
  background: #1565c0;
  color: #e3f2fd;
  cursor: pointer;
}

.delete {
  background: #c62828;
  color: #e3f2fd;
  cursor: pointer;
}

.save {
  background: #2e7d32;
  color: #e3f2fd;
  cursor: pointer;
}

.layout-container {
  display: flex;
  height: 100vh;
  width: 100vw;
  justify-content: center;
  align-items: center;
}

.login-item {
  background-color: #BDBDBD;
  border-radius: 4px;
  width: 100%;
  max-width: 300px;
  height: 500px;
  box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
}

.style-5 input[type="text"] {
  padding: 10px;
  box-sizing: border-box;
  border: solid 1px #9E9E9E;
  box-shadow: inset 1px 1px 2px 0 #707070;
  -webkit-transition: box-shadow 0.3s;
  transition: box-shadow 0.3s;
}
.style-5 input[type="text"]:focus, .style-5 input[type="text"].focus {
  box-shadow: inset 1px 1px 2px 0 #c9c9c9;
}

.style-5 input[type="password"] {
  padding: 10px;
  box-sizing: border-box;
  border: solid 1px #9E9E9E;
  box-shadow: inset 1px 1px 2px 0 #707070;
  -webkit-transition: box-shadow 0.3s;
  transition: box-shadow 0.3s;
}
.style-5 input[type="password"]:focus, .style-5 input[type="text"].focus {
  box-shadow: inset 1px 1px 2px 0 #c9c9c9;
}

input[type="submit"] {
  border: solid 1px #9E9E9E;
  background: #1565C0;
  color: #E3F2FD;
  cursor: pointer;
}

input[type="submit"]:disabled,
input[type="submit"][disabled]{
  background-color: #757575;
  color: #424242;
}

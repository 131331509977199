body {
    margin: 0;
    padding: 0;
    font-family: sans-serif;
}

.lineunderbold {
    border-top: 4px solid grey;
    padding-bottom: 20px;
}

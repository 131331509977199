.layout-container {
  display: flex;
  height: 100vh;
  width: 100vw;
  justify-content: center;
  align-items: center;
}

.login-item {
  background-color: #BDBDBD;
  border-radius: 4px;
  width: 100%;
  max-width: 300px;
  height: 500px;
  box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
}

.style-5 input[type="text"] {
  padding: 10px;
  box-sizing: border-box;
  border: solid 1px #9E9E9E;
  -webkit-box-shadow: inset 1px 1px 2px 0 #707070;
  -moz-box-shadow: inset 1px 1px 2px 0 #707070;
  box-shadow: inset 1px 1px 2px 0 #707070;
  -webkit-transition: box-shadow 0.3s;
  -moz-transition: box-shadow 0.3s;
  -o-transition: box-shadow 0.3s;
  transition: box-shadow 0.3s;
}
.style-5 input[type="text"]:focus, .style-5 input[type="text"].focus {
  -webkit-box-shadow: inset 1px 1px 2px 0 #c9c9c9;
  -moz-box-shadow: inset 1px 1px 2px 0 #c9c9c9;
  box-shadow: inset 1px 1px 2px 0 #c9c9c9;
}

.style-5 input[type="password"] {
  padding: 10px;
  box-sizing: border-box;
  border: solid 1px #9E9E9E;
  -webkit-box-shadow: inset 1px 1px 2px 0 #707070;
  -moz-box-shadow: inset 1px 1px 2px 0 #707070;
  box-shadow: inset 1px 1px 2px 0 #707070;
  -webkit-transition: box-shadow 0.3s;
  -moz-transition: box-shadow 0.3s;
  -o-transition: box-shadow 0.3s;
  transition: box-shadow 0.3s;
}
.style-5 input[type="password"]:focus, .style-5 input[type="text"].focus {
  -webkit-box-shadow: inset 1px 1px 2px 0 #c9c9c9;
  -moz-box-shadow: inset 1px 1px 2px 0 #c9c9c9;
  box-shadow: inset 1px 1px 2px 0 #c9c9c9;
}

input[type="submit"] {
  border: solid 1px #9E9E9E;
  background: #1565C0;
  color: #E3F2FD;
  cursor: pointer;
}

input[type="submit"]:disabled,
input[type="submit"][disabled]{
  background-color: #757575;
  color: #424242;
}
/*.card {
    box-shadow: 0 8px 4px 0 rgba(0, 0, 0, 0.2);
    max-width: 300px;
    text-align: center;
    margin-bottom: 25px;
}
*/

.card {
  min-height: 100%;
  background: white;
  box-shadow: 0 8px 4px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  text-decoration: none;
  text-align: center;
  color: #444;
  position: relative;
  top: 0;
  transition: all 0.1s ease-in;
  max-width: 300px;
}

html {
  background: #f5f7f8;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
}

.band {
  width: 100%;
  max-width: 1240px;
  margin: 0 auto;
  display: grid;

  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-gap: 20px;
}

@media only screen and (min-width: 970px) {
  .band {
    grid-template-columns: 1fr 1fr;
  }
}

@media only screen and (min-width: 1300px) {
  .band {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.bandP {
  width: 95%;
  max-width: 1240px;
  margin: 0 auto;
  display: grid;

  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-gap: 5px;
}

@media only screen and (min-width: 650px) {
  .bandP {
    grid-template-columns: 1fr 1fr;
  }
}

/* card */

.card {
  min-height: 100%;
  background: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  text-decoration: none;
  color: #444;
  position: relative;
  top: 0;
  transition: all 0.1s ease-in;
}

.card:hover {
  top: -4px;
  box-shadow: 0 9px 8px rgba(0, 0, 0, 0.3);
}

.card article {
  padding: 20px;
  display: flex;

  flex: 1;
  justify-content: space-between;
  flex-direction: column;
}
.card .thumb {
  padding-bottom: 60%;
  background-size: cover;
  background-position: center center;
  background-image: url();
}

.card p {
  flex: 1; /* make p grow to fill available space*/
  line-height: 1.4;
  overflow-wrap: break-word;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}

.prodName {
  flex: 1;
  line-height: 1.6;
  overflow-wrap: break-word;
  hyphens: auto;
  max-width: 300px;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
}

/* typography */

.card span {
  font-size: 12px;
  font-weight: bold;
  color: #999;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  margin: 2em 0 0 0;
}

.price {
  color: black;
  font-size: 22px;
  font-weight: 600;
}

.true {
  color: green;
  font-size: 18px;
}
.w3-check {
  border: 2px;
  dotted: #00f;
  display: block;
  background: #ff0000;
}

.false {
  color: red;
  font-size: 18px;
}

.button1 {
  display: inline-block;
  padding: 15px 25px;
  border: none;
  border-radius: 8px;
  outline: none;
  display: inline-block;
  padding: 8px;
  color: white;
  background-color: #008cba;
  text-align: center;
  cursor: pointer;
  width: 100%;
  font-size: 14px;
  max-width: 100px;
  margin-bottom: 4px;
}

.a {
  text-decoration: none;
  font-size: 22px;
  color: black;
}

.button1:hover,
a:hover {
  opacity: 0.9;
}

.picture {
  max-width: 300px;
}

.switchdiv {
  margin: 5px 10px 0px 0px;
  position: relative;
  display: inline-block;
}

.smaller {
  margin-bottom: 0px;
}

.switchcontainer {
  margin-bottom: 40px;
}

.product {
  padding-top: 100px;
}

.lineunder {
  border-top: 1px solid grey;
  padding-bottom: 20px;
}

@media all and (max-width: 700px) {
  .title {
    margin-top: 5px;
  }
  .gap {
    padding-left: 45px;
  }
}

.label {
  margin-right: 7px;
  font-size: 18px;
  color: grey;
  justify-content: center;
}

.flex-container {
  margin-top: 5px;
  display: flex;
  justify-content: center;
  background-color: white;
}

.flex-div {
}

.change {
  background: #1565c0;
  color: #e3f2fd;
  cursor: pointer;
}

.delete {
  background: #c62828;
  color: #e3f2fd;
  cursor: pointer;
}

.save {
  background: #2e7d32;
  color: #e3f2fd;
  cursor: pointer;
}

.add-input {
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px;
  width: 80%;
  box-sizing: border-box;
  border: solid 1px #9e9e9e;
  -webkit-box-shadow: inset 1px 1px 2px 0 #707070;
  -moz-box-shadow: inset 1px 1px 2px 0 #707070;
  box-shadow: inset 1px 1px 2px 0 #707070;
  -webkit-transition: box-shadow 0.3s;
  -moz-transition: box-shadow 0.3s;
  -o-transition: box-shadow 0.3s;
  transition: box-shadow 0.3s;
}
.add-input:focus,
.add-input.focus {
  -webkit-box-shadow: inset 1px 1px 2px 0 #c9c9c9;
  -moz-box-shadow: inset 1px 1px 2px 0 #c9c9c9;
  box-shadow: inset 1px 1px 2px 0 #c9c9c9;
}

.add-inputText {
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px;
  box-sizing: border-box;
  border: solid 1px #9e9e9e;
  -webkit-box-shadow: inset 1px 1px 2px 0 #707070;
  -moz-box-shadow: inset 1px 1px 2px 0 #707070;
  box-shadow: inset 1px 1px 2px 0 #707070;
  -webkit-transition: box-shadow 0.3s;
  -moz-transition: box-shadow 0.3s;
  -o-transition: box-shadow 0.3s;
  transition: box-shadow 0.3s;
  width: 100%;
  height: 70px;
}
.add-inputText:focus,
.add-inputText.focus {
  -webkit-box-shadow: inset 1px 1px 2px 0 #c9c9c9;
  -moz-box-shadow: inset 1px 1px 2px 0 #c9c9c9;
  box-shadow: inset 1px 1px 2px 0 #c9c9c9;
}

.showInputText {
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px;
  box-sizing: border-box;
  border: solid 0.2px #9e9e9e;
  width: 80%;
  height: 70px;
  background-color: #f8f8f8;
}

.showInputN {
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px;
  box-sizing: border-box;
  border: solid 1px #9e9e9e;
  width: 50%;
  height: 30px;
  background-color: #f8f8f8;
}

.showInput {
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px;
  box-sizing: border-box;
  border: solid 0.2px #9e9e9e;
  width: 80%;
  height: 30px;
  background-color: #f8f8f8;
}

#settings {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

#settings .navbar {
  order: -1;
}

#settings .body {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  order: 0;
  align-items: center;
}
#settings .body .profile {
  display: flex;
  justify-content: space-around;
  padding: 10px;
  order: 0;
  flex: 1 0 auto;
  max-width: 700px;
  width: 100%;
  align-items: center;
}

.requiresReservation {
  font-size: 18;
}

.taxRate {
  font-size: 18;
}

@media screen and (max-width: 730px) {
  #settings .body {
    align-items: stretch;
  }
  #settings .body .profile {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
    order: 0; /* i forhold til notifications */
    flex: 1 0 auto;
  }
  #settings .body .profile #profileData {
    max-width: 270px;
    margin-bottom: 30px;
    padding-top: 0;
  }
  #settings .body .profile #profileData table {
    max-width: 300px;
    margin: 0;
  }
}

/* Profile picture */
#profilePicture {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 26px;
}

#profilePicture img {
  max-width: 270px;
  max-height: 270px;
  border-radius: 50%;
}
#profilePicture .file-upload {
  display: flex;
  justify-content: center;
  align-self: stretch;
  padding: 16px 0;
  background-color: #df8600;
  border: none;
  text-decoration: none;
  cursor: pointer;
  margin: 5px 0;
  color: black;
  outline: none;
  border-radius: 4px;
}
#profilePicture .file-upload:hover {
  border: 3px solid #646464;
}
#profilePicture .file-upload input {
}
#profilePicture .file-upload .fa-cloud-upload {
}

#profilePicture button {
  align-self: stretch;
  padding: 16px 0;
  background-color: black;
  border: none;
  text-decoration: none;
  cursor: pointer;
  margin: 5px 0;
  color: #a2a2a2;
  outline: none;
  border-radius: 4px;
}
#profilePicture button:hover {
  border: 3px solid #646464;
}

#profilePicture input[type="file"] {
  display: none;
}
#profilePicture .custom-file-upload {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
}

/* edit password button */
#editPasswordButton {
  display: flex;
  flex-direction: column;
  padding: 0 0;
}

#editPasswordButton button {
  align-self: stretch;
  padding: 16px 0;
  background-color: #df8600;
  border: none;
  text-decoration: none;
  cursor: pointer;
  margin: 5px 0;
  color: black;
  outline: none;
  border-radius: 4px;
}
#editPasswordButton button:hover {
  border: 3px solid #646464;
}

/* Profile data */
#profileData {
  display: flex;
  flex-direction: column;
  margin-top: 14px;
  padding-top: 140px;
}
#profileData table {
  margin: 0 0 0 0;
}
#profileData table tbody td {
  min-width: 100px;
  max-width: 150px;
  word-break: break-all;
}
/*
#profileData #userinfo {
    flex-basis: 270px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin: 0 10px;

}
#profileData #userinfo div{
    display: flex;
    justify-content: space-between;
}
*/

#profileData button {
  /*
    align-self: stretch;
     */
  background-color: #df8600;
  border: none;
  text-decoration: none;
  cursor: pointer;
  padding: 16px 16px;
  margin: 5px 2px 5px 2px;
  color: black;
  outline: none;
  border-radius: 4px;
}

#profileData button:hover {
  border: 3px solid #646464;
}

/* notifications */
#notifications {
  display: flex;
  flex-direction: column;
  align-self: center;
  margin-top: 20px;
  /*
    padding: 40px;
     */
  order: 1; /* i forhold til profile */
}
#notifications div {
  display: flex;
  margin: 6px;
}
#notifications div input[type="checkbox"] {
  width: 20px;
  height: 20px;
}
#notifications p {
  margin: 4px 0;
  text-align: center;
}
#notifications div label {
}
#notifications button {
  background-color: black;
  border: none;
  color: #a2a2a2;
  padding: 16px 32px;
  text-decoration: none;
  cursor: pointer;
  text-align: center;
  outline: none;
  border-radius: 4px;
  align-self: stretch;
}
#notifications button:hover {
  border: 3px solid #646464;
}

#settings .footer {
  order: 1;
}

input:invalid {
  border: 1px solid red;
}

input:valid {
  border: 1px solid black;
}

textarea:invalid {
  border: 1px solid red;
}

textarea:valid {
  border: 1px solid black;
}
